.buttonload {
    background-color: var(--redColor);
    border: none;
    color: white;
    text-transform: capitalize;
    padding: 11px 15px;
    width: 100%;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 15px;

}

.buttonSpinner {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, white 94%, #0000) top/9px 9px no-repeat,
        conic-gradient(#0000 30%, white);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
    100% {
        transform: rotate(1turn);
    }
}