@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;

}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.17em;
}

h4 {
    font-size: 1em;
}

h5 {
    font-size: .83em;
}

h6 {
    font-size: .67em;
}

html {
    scroll-behavior: smooth;
}

body {
    text-transform: capitalize;
    overflow-x: hidden !important;
    font-family: "Cairo", sans-serif;
}

:root {
    --redColor: #de0712;
    --blackColor: #000;
    --yellowColor: #f3de6d;
    --grayColor: #fafafa;
    --textColor: #555;
    --sectionColor: rgba(252, 200, 199, 0.3);
    --darkColor: #191c1f;
    --graySection: #fafafa;
}

.main {
    min-height: calc(100vh - 560px);
}

/* Hide spinner arrows for input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.dir {
    direction: ltr !important;
}

/* تخصيص الـ Scroll Bar */
.custom-slider::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.custom-slider::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* لون الخلفية */
}

.custom-slider::-webkit-scrollbar-thumb {
    background: #888;
    /* لون شريط التمرير */
    border-radius: 10px;
    /* جعل الشريط دائري */
}

.custom-slider::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* تغيير اللون عند التمرير */
}

/* slick dots */
.slick-dots li {
    margin: 0 10px !important;
    /* تعديل المسافة بين الـ dots */
}



/* تنسيق الـ active dot */
.slick-dots li.slick-active div {
    background-color: #de0712;
    color: white !important;
    border-color: #de0712;
}

.category-slider::-webkit-scrollbar {
    height: 8px;

}

.category-slider::-webkit-scrollbar-track {
    background: #f1f1f1;

}

.category-slider::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;

}

.category-slider::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}



.custom-scroll::-webkit-scrollbar {
    height: 3px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.desc ul,
.desc li {
    list-style-type: disc !important;
}

a.active {
    color: var(--redColor) !important;
    font-weight: bold !important;
    border-bottom: 1px solid var(--redColor) !important;
}

.animate-to-cart {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1000;
    top: 0;
    left: 0;
    pointer-events: none;
}